import { DataContext } from '@/components/context/DataContext';
import { useContext, useEffect } from 'react';

const F3KeyListener = () => {
    const { setIsF3Entered } = useContext(DataContext);

    useEffect(() => {
        const handleF3KeyPress = (event) => {
            if (event.code === 'F3') {
                event.preventDefault();
                const searchInput = document.getElementById('global-search');

                if (searchInput) {
                    searchInput.focus();
                    setIsF3Entered(true);
                }
            }
        };

        window.addEventListener('keydown', handleF3KeyPress);
        return () => {
            window.removeEventListener('keydown', handleF3KeyPress);
        };
    }, []);
    return null;
};

export default F3KeyListener;
