import { useEffect, useRef, useState } from 'react';

const CircularCountDown = ({
    seconds, totalSeconds, onExpiry
}) => {
    const [timer, setTimer] = useState(seconds);
    const [progress, setProgress] = useState((seconds / totalSeconds) * 100);
    const [hasExpired, setHasExpired] = useState(false);
    const startTimeRef = useRef(Date.now());
    const remainingTimeRef = useRef(seconds);

    useEffect(() => {
        setTimer(seconds);
        setHasExpired(false);
        startTimeRef.current = Date.now();
        remainingTimeRef.current = seconds;
    }, [seconds]);

    useEffect(() => {
        const updateTimer = () => {
            const now = Date.now();
            const elapsedSeconds = Math.floor((now - startTimeRef.current) / 1000);
            const newTimer = Math.max(remainingTimeRef.current - elapsedSeconds, 0);

            setTimer(newTimer);
            setProgress((newTimer / totalSeconds) * 100);

            if (newTimer === 0 && !hasExpired) {
                setHasExpired(true);
                onExpiry();
            }
        };

        const interval = setInterval(updateTimer, 1000);

        return () => clearInterval(interval);
    }, [totalSeconds, onExpiry, hasExpired]);

    return (
        <div className="flex items-center justify-center bg-gray-700">
            <div
                className="relative flex items-center justify-center w-7 h-7 rounded-full"
                style={{
                    background: `conic-gradient(red ${progress}%, gray ${progress}% 100%)`
                }}
            >
                <div className="absolute flex flex-col items-center justify-center bg-gray-700 rounded-full w-5 h-5">
                    <span className="text-red-500 text-[8px] font-bold">{timer}</span>
                </div>
            </div>
        </div>
    );
};

export default CircularCountDown;
