import { AlertCircle } from 'react-feather';
import Text from '../atoms/Text';
import commonDropdownStyle from './commonDropDownStyles';
import codes from './countryCode';

export const traderTypes = ['Bank', 'RM', 'HF', 'PB'];
export const complianceRoles = ['Compliance', 'Operation'];

export const countryCodes = codes;

export const tradeTypes = {
    Bid: {
        action: 'hit',
        type: 'bid'
    },
    Offer: {
        action: 'lift',
        type: 'offer'
    }
};

export const popupSpecs = 'width=800,height=2000,left=0,popup';
export const popupSpecsForDes = 'width=1100,height=2000,left=0,popup';
export const popupSpecsForMatch = 'width=1200,height=2000,left=0,popup';
export const popupSpecsForDownloadOrders = 'width=1104,height=1024,left=0,popup';
export const popupSpecsForTrade = 'width=1320,height=2000,left=0,popup';
export const popupSpecsForQuickPrice = 'width=1480,height=2000,left=0,popup';
export const windowFeatures = 'popup=true,left=0,top=0,right=0,bottom=0,width=528,height=2000';

export const isValidImageFormat = (format) => ['jpg', 'jpeg', 'png', 'svg'].includes(format?.toLowerCase());
export const isValidVideoFormat = (format) => ['mp4'].includes(format?.toLowerCase());
export const isValidFileFormat = (format) => ['pdf', 'txt', 'doc', 'docx', 'csv', 'xlsx', 'xls'].includes(format?.toLowerCase());

export const formatFileSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
};
export const loaderDisabledPages = ['/', '/error', '/chat', '/bonds/create-bonds', '/agora', '/termandcondition', '/product',
    '/regulatoryInfo/DeonRuleBook', '/liveprices', '/liveprices/macro', '/liveprices/bonds'
];

export const convertStringToFixedFloat = (number, decimalPlaces) => {
    let value = number;
    if (!value) return 0;
    if (typeof value === 'string') {
        value = parseFloat(value);
        if (isNaN(value)) {
            return 0;
        }
    }
    return Number(value.toFixed(decimalPlaces));
};
export const getSizeFormatter = (val) => {
    if (val >= 1000000000) {
        return { value: Number((val / 1000000000).toFixed(3)), unit: 'Bn' };
    }
    if (val >= 1000000) {
        return { value: Number((val / 1000000).toFixed(3)), unit: 'Mn' };
    }
    if (val >= 1000) {
        return { value: Number((val / 1000).toFixed(3)), unit: 'K' };
    }
    if (val >= 0) {
        return { value: Number(val / 1000), unit: 'K' };
    }
    return { value: val, unit: '' };
};
export const getFormattedSize = (val) => {
    const { value, unit } = getSizeFormatter(val);
    return `${value}${unit}`;
};

export const formatAmount = (amount) => new Intl.NumberFormat('en-US').format(amount);
export const rfqCounterTimer = 60000;
export const irsLiveTime = 2 * 60 * 1000;
export const currencyLiveTime = 2 * 60 * 1000;
export const NdfDefaultCurrency = 'USD';
export const irsTenureValues = {
    d: 1, D: 1, w: 2, W: 2, m: 3, M: 3, y: 4, Y: 4
};
export const irsTerms = ['1 W', '2 W', '1 M', '3 M', '6 M', '9 M', '1 Y', '2 Y', '3 Y', '4 Y', '5 Y', '7 Y', '10 Y', '15 Y', '20 Y', '30 Y'];
export const currencyTerms = ['1 D', '3 D', '1 W', '2 W', '1 M', '2 M', '3 M', '6 M', '9 M', '1 Y'];
export const currencyLSTerms = ['1 D', '2 D', '3 D', '4 D', '5 D', '1 W', '2 W', '3 W', '1 M', '2 M', '3 M', '4 M', '5 M', '6 M', '7 M', '8 M', '9 M', '10 M', '11 M', '12 M'];
export const bondLevels = ['Price', 'Spread', 'Yield', 'IG'];

export const daysByDayNumber = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
};
export const dropDownStyle = {
    control: (provided, state) => ({
        ...provided,
        background: '#FE9628',
        borderWidth: '0px',
        borderColor: 'none',
        cursor: 'pointer',
        boxShadow: 'none',
        width: '136px',
        minHeight: '28px',
        padding: '0px 14px',
        borderRadius: '6px',
        '&:active': {
            borderColor: '#2962FF'
        },
        '.select2-selection__single-value': {
            fontSize: '12px',
            fontWeight: '600',
            color: '#000'
        },
        '.select2-selection__value-container': {
            padding: '0'
        },
        '.select2-selection__indicator': {
            width: '15px'
        },
        '.select2-selection__input': {
            color: '#000 !important',
            '.select2-selection__input::placeholder': {
                color: '#000 !important'
            }
        },
        '.select2-selection__multi-value': {
            background: '#1E222D',
            color: '#fff',
            padding: '4px 8px;',
            marginRight: '4px 6px',
            '.select2-selection__multi-value__label': {
                color: '#fff',
                padding: '0',
                marginRight: '8px',
                fontSize: '12px',
                lineHeight: '16px'
            },
            '.select2-selection__multi-value__remove': {
                padding: 0,
                '&:hover': {
                    background: 'none',
                    color: '#fff'
                }
            }
        }

    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 50,
        backgroundColor: '#1E222D'

    }),

    option: (provided) => ({
        ...provided,
        color: '#868993',
        fontSize: '12px',
        backgroundColor: '#1E222D',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#2A2E39',
            color: '#D1D4DC'
        }
    }),

    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        padding: '0 12px'

    }),

    placeholder: (provided) => ({
        ...provided,
        whiteSpace: 'nowrap',
        color: '#000',
        fontSize: '12px',
        lineHeight: '16px'
    }),

    input: (provided) => ({
        ...provided,
        margin: '0px',
        fontSize: '12px'
    }),
    indicators: () => ({
        width: '15px'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '100%'
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        position: 'absolute',
        top: '-7px',
        backgroundColor: 'red',
        padding: '2px',
        right: '-4px',
        borderRadius: '100px',
        fontSize: '10px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '15px',
        height: '15px',
        '&:hover': {
            color: '#000',
            padding: '1px'
        }
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: '#000',
        padding: ' 0',
        strokeWidth: '5px',
        marginTop: '3px',
        '&:hover': {
            color: '#000'
        }
    })
};
export const selectCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#FD9626',
        borderWidth: '0px',
        borderColor: 'none',
        cursor: 'pointer',
        boxShadow: 'none',
        width: '74px',
        minHeight: '32px',
        borderRadius: '6px',
        '&:active': {
            borderColor: '#2962FF'
        },
        '.select2-selection__single-value': {
            fontSize: '14px',
            fontWeight: '600',
            color: '#000',
            textAlign: 'right'
        },
        '.select2-selection__input': {
            color: '#000 !important',
            '.select2-selection__input::placeholder': {
                color: '#000 !important'
            }
        },
        '.select2-selection__multi-value': {
            background: '#1E222D',
            color: '#fff',
            padding: '4px 8px;',
            marginRight: '4px 6px',
            '.select2-selection__multi-value__label': {
                color: '#fff',
                padding: '0',
                marginRight: '8px',
                fontSize: '12px',
                lineHeight: '16px'
            },
            '.select2-selection__multi-value__remove': {
                padding: 0,
                '&:hover': {
                    background: 'none',
                    color: '#fff'
                }
            }
        }

    }),
    menu: (provided) => ({
        ...provided,

        backgroundColor: '#1E222D'

    }),

    option: (provided) => ({
        ...provided,
        color: '#868993',
        fontSize: '12px',
        backgroundColor: '#1E222D',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#2A2E39',
            color: '#fff'
        }
    }),

    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        padding: '0 0 0 12px'

    }),

    placeholder: (provided) => ({
        ...provided,
        whiteSpace: 'nowrap',
        color: '#fff',
        fontSize: '12px',
        lineHeight: '16px'
    }),

    input: (provided) => ({
        ...provided,
        margin: '0px',
        fontSize: '12px'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '100%'
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        position: 'absolute',
        top: '-7px',
        backgroundColor: 'red',
        padding: '2px',
        right: '-4px',
        borderRadius: '100px',
        fontSize: '10px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '15px',
        height: '15px',
        '&:hover': {
            color: '#000',
            padding: '1px'
        }
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: '#000',
        padding: '4px 8px 4px 2px'

    })
};

export const priceBlinkDuration = 15 * 1000; // 15 seconds
export const priceGreyOutDuration = 60 * 1000; // 60 seconds

export const bondStatus = {
    DEFAULTED: 'DEFAULTED',
    LIVE: 'LIVE',
    CALLED: 'CALLED',
    MATURED: 'MATURED'
};

export const livePriceRoutes = ['/liveprices', '/liveprices/macro', '/liveprices/bonds'];
export const isHootDisabled = true;
export const isChatDisabled = true;

const customTimeOption = () => <div className='flex text-xs gap-x-1 items-center'>
    GTC (Good Till Cancel)
    <div className='group relative hidden time-limit-tooltip'>
        <AlertCircle size={13} className='text-gray-200 cursor-pointer group-hover:text-gray-100' />
        <div className='bg-gray-700 rounded-md p-2 z-50 absolute hidden group-hover:block cursor-pointer time-tooltip-text'>
            <Text variant="small" className="text-gray-100">Good Till Cancel - The price is live till you cancel it,
                or else it will automatically be deleted at 12:00 noon SGT for the morning session and 6:00 PM SGT for the afternoon session.</Text>
        </div>
    </div>
</div>;
export const timeLimitOptions = [
    { value: 60, label: 'Go live for 1 min' }, { value: 120, label: 'Go live for 2 mins' },
    { value: 600, label: 'Go live for 10 mins' },
    { value: 3600, label: 'Go live for 1 hour' }, { value: 0, label: customTimeOption() }];

export const priorityMap = {
    Bond: 0, Irs: 1, Ndf: 2, Equity: 4, Currency: 5, QuickCurrency: -1, Cds: 3, MaturedBond: 6
};

export const globalCommandOptions = ({ bondType }) => [{
    category: 'Functions',
    items: [{
        command: 'PLOT',
        type: 'Function',
        group: 'Functions',
        formattingStyle: 'html',
        label: 'Plot',
        html: <div>
            Plot Graph{' '}
            <span className="text-gray-200">
                &lt;curvename&gt; &lt;tenor(s)&gt; (eg: INR NDOIS 3Y 5Y 7Y) (Eg: INR NDF 1M 2M)
            </span>
        </div>
    },
    {
        command: 'FWCM',
        type: 'Function',
        group: 'Functions',
        formattingStyle: 'html',
        label: 'FWCM',
        html: <div>
            Forward Curve Matrix{' '}
            <span className="text-gray-200">
                &lt;curve name&gt; (eg: FWCM INR NDOIS, FWCM AUD IRS)
            </span>
        </div>
    },
    {
        command: 'INP',
        type: 'Function',
        group: 'Functions',
        formattingStyle: 'html',
        label: 'Input form <Curve name> <Start date> <Tenor(s)> <Size> <DV01/Notional> <B/O> (Eg: KRW NDIRS spot 1M 12M 3k 9.6b)',
        html: <div>
            Input form{' '}
            <span className="text-gray-200">
                {bondType === 'IRS' ? (
                    <>
                        &lt;Curve name&gt; &lt;Start date&gt; &lt;Tenor(s)&gt; &lt;Size&gt; &lt;DV01/Notional&gt;
                        &lt;B/O&gt; (Eg: KRW NDIRS spot 1M 12M 3k 9.6b)
                    </>
                ) : (
                    <>
                        &lt;Curve name&gt; &lt;Tenor(s)&gt; &lt;Notional&gt; &lt;B/O&gt; (Eg: KRW NDF 1W 1Y 5Mn 5.6b)
                    </>
                )}
            </span>
        </div>
    },
    {
        command: 'CORP',
        type: 'Function',
        group: 'Functions',
        formattingStyle: 'html',
        label: 'Shows all bonds with a specified ticker (Eg: CORP INDON, INDON CORP, INDON <F3 | enter>)',
        html: <div>
        Shows all bonds with a specified ticker{' '}
            <span className="text-gray-200">
            (Eg: CORP INDON, INDON CORP, INDON &lt;F3 | enter&gt;)
            </span>
        </div>
    },
    {
        command: 'OTC',
        type: 'Function',
        group: 'Functions',
        formattingStyle: 'html',
        label: 'Over-the-counter <Currency> (Eg: OTC INR, OTC NZD)',
        html: <div>
            Over-the-counter{' '}
            <span className="text-gray-200">
                &lt;Currency&gt; (Eg: OTC INR, OTC NZD)
            </span>
        </div>
    },
    {
        command: 'SHARE', type: 'Function', group: 'Functions', label: 'Share Screen Image'
    },
    {
        command: 'TOP', type: 'Function', group: 'Functions', label: 'Top News'
    },
    {
        command: 'CN', type: 'Function', group: 'Functions', label: 'Company News'
    },
    {
        command: 'TRADES', type: 'Function', group: 'Functions', label: 'All Trades'
    },
    {
        command: 'TRSY', type: 'Function', group: 'Functions', label: 'TRSY'
    },
    {
        command: 'GP', type: 'Function', group: 'Functions', label: 'Line Chart'
    },
    {
        command: 'HP', type: 'Function', group: 'Functions', label: 'Price History'
    }
    ]
}];

const createCommandDetailsMapping = (bond, isBond, isEquity) => ({
    DES: `${bond} Details`,
    GP: `${bond} History Plot`,
    HP: `${bond} Price History`,
    YAS: `${bond} Yield Analysis`,
    CN: `${bond} ${isBond ? 'Company News' : 'News'}`,
    GIP: `${bond} Intraday ${isEquity ? 'Trading Chart' : 'Price Chart'}`,
    TOP: 'Top News',
    TRADES: 'All Trades'
});

export const getBlurb = ({
    bond, command, isBond = false, isEquity = false
}) => {
    if (!bond) return '';
    if (!command) return `${bond} Details`;

    return createCommandDetailsMapping(bond, isBond, isEquity)[command] ?? '';
};

export const extractBondBlurb = (bond) => {
    const parts = String(bond ?? '').split(' ');
    const bondName = parts[0];

    const datePart = parts.find((part) => (part.match(/\//g) || []).length === 2);
    if (!datePart) return bondName;

    const maturityYear = datePart.split('/').pop();

    return `${bondName} ${maturityYear}`;
};

export const bondBasicDropdownStyle = {
    ...commonDropdownStyle,
    control: (provided, state) => ({
        ...commonDropdownStyle.control(provided, state),
        background: '#FE9628',
        width: '100%',
        padding: '0px 14px',
        borderRadius: '0px',
        '.select2-selection__single-value': {
            ...commonDropdownStyle.control(provided, state)['.select2-selection__single-value'],
            fontWeight: '600',
            color: '#000'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        whiteSpace: 'nowrap',
        color: '#000',
        fontSize: '12px',
        lineHeight: '16px'
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: '#000',
        padding: '0',
        strokeWidth: '5px',
        marginTop: '3px',
        '&:hover': {
            color: '#000'
        }
    })
};
