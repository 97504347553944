import { createContext, useState } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [fetchedTraders, setFetchedTraders] = useState();
    const [isF3Entered, setIsF3Entered] = useState(false);

    return <DataContext.Provider
        value={
            {
                fetchedTraders,
                setFetchedTraders,
                isF3Entered,
                setIsF3Entered
            }}
    >
        {children}
    </DataContext.Provider>;
};
