/* eslint-disable no-restricted-syntax */
import { DataContext } from '@/components/context/DataContext';
import { StatusContext } from '@/components/context/StatusContext';
import Text from 'atoms/Text';
import { popupSpecsForDes, priorityMap } from 'lib/constants';
import debounce from 'lodash.debounce';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
    useContext, useEffect, useRef, useState
} from 'react';
import 'regenerator-runtime/runtime';
import { getAuth } from 'services/auth.service';
import { socket, socketConnect, socketEmit } from 'services/socket';
import SearchBox from '../atoms/SearchBox';
import { trackEvent } from '../helper/mixPanel';

const Dictaphone = dynamic(() => import('../components/Dictaphone'), {
    ssr: false
});

const GlobalSearch = ({
    icon, iconClass, options = [], onSelect, placeholder, clubUsersIntoFunctions = false, inputClass, forDashboard = true, forDetailsPage = false,
    suggestionBg, categoryTitleClass, categoryList, highlightBg, suggestionName, globalSearchList,
    takeScreenShot, selectedCommand = 'DES', setYasPrice = () => { }, voiceCommand = false, plot = () => { },
    curve = '', isPlotPage = false, legs = [], fwcm = () => { }, setTicker = () => { }, isCorpChildComponent = false
}) => {
    const enableVoiceCommand = false;
    const [searchPhrase, setSearchPhrase] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const inputRef = useRef(null);
    const [highlightedId, setHighlightedId] = useState('');
    const parentIndex = useRef(0);
    const childIndex = useRef(-1);
    const [commandsAndUsers, setCommandsAndUsers] = useState(options);
    const allSuggestions = [...commandsAndUsers, ...suggestions];
    const { isF3Entered, setIsF3Entered } = useContext(DataContext);
    const searchBoxRef = useRef(null);
    const yasWithPriceRegex = /^yas (\d+(\.\d+)?)$/;
    const matchYasCommandWithPrice = (str) => yasWithPriceRegex.test(str);
    const router = useRouter();

    const { globalCommand } = useContext(StatusContext);
    const auth = getAuth();

    const getPriceFromYasWithPrice = (str) => {
        const match = str.match(yasWithPriceRegex);
        if (match) {
            return match[1];
        }
        return null;
    };
    const parsePlot = (text) => {
        const trimmedText = text.toLowerCase().trim();
        const plotPattern = /^plot\s+(.*?)((?:\d+\s*(?:[mywd])?\s?(?:\s+fwd)?\s?)+)(spot)?$/;
        const generalPattern = /^(.+?)\s((?:\d+\s*(?:[mywd])?\s?(?:\s+fwd)?\s?)*)(spot)?$/;

        let match = trimmedText.match(generalPattern);
        const plotMatch = plotPattern.test(trimmedText);

        if (plotMatch) {
            match = trimmedText.match(plotPattern);
        }

        if (!match && !plotMatch) return { isValid: false };

        let name = match[1] ? match[1].trim() : '';
        let spot = false;
        if (match[3] === 'spot')spot = true;
        if (name.endsWith('spot')) {
            if (spot) return { isValid: false };
            name = name.substring(0, name.length - 4).trim();
            spot = true;
        }
        const tenors = match[2].trim().split(/\s+/).map((tenor) => (/^\d+$/.test(tenor) ? `${tenor}y` : tenor));
        let isFwd = false;
        const cleanedTenors = [];
        let fwdTenor = null;
        let fwdCount = 0;

        for (let i = 0; i < tenors.length; i++) {
            if (tenors[i] === 'fwd' && i > 0) {
                fwdCount++;
                if (fwdCount > 1) {
                    return { isValid: false };
                }
                isFwd = true;
                fwdTenor = tenors[i - 1];
            } else if (/^\d+[mywd]$/.test(tenors[i])) {
                cleanedTenors.push(tenors[i]);
            }
        }
        if (isFwd) {
            cleanedTenors.sort((a, b) => (a === fwdTenor ? -1 : 1));
        }
        const isValidTenors = cleanedTenors.every((tenor) => /^\d+[mywd]$/.test(tenor));
        const isValidLength = isFwd ? cleanedTenors.length === 2 : ((spot && cleanedTenors.length === 1) || (!spot && ((plotMatch && !name)) || cleanedTenors.length >= 2));
        if (!isValidTenors || !isValidLength) {
            return { isValid: false };
        }
        return {
            name, tenors: cleanedTenors, isFwd, spot, isValid: true
        };
    };

    const parseInput = (text) => {
        if (!text.trim().toUpperCase().startsWith('INP')) {
            return { isValid: false };
        }
        const reservedStartWords = ['spot', 'h5', 'm5', 'u5', 'z5', 'h6', 'm6', 'u6', 'z6']; // Reserved words for 'start'
        const tenorPattern = /^\d+[mywd]$/i;
        const dv01Pattern = /\b\d+[k]\b/i;
        const pricePattern = /^\b\d+(\.\d+)?(o|b|p|r|bid|offer|pay|rec|receive)?\b$/i;
        const sizePattern = /\b\d+(Mn|Mm|Mio)\b/i;

        const result = {
            curveName: null,
            start: null,
            tenors: [],
            dv01: null,
            price: null,
            size: null,
            isValid: false
        };

        const parts = text.slice(3).trim().split(/\s+/);

        const curveName = [];
        let remainingParts = [];

        for (const part of parts) {
            if (
                reservedStartWords.includes(part.toLowerCase())
                || tenorPattern.test(part)
                || dv01Pattern.test(part)
                || pricePattern.test(part)
                || sizePattern.test(part)
            ) {
                remainingParts = parts.slice(parts.indexOf(part));
                break;
            }
            curveName.push(part);
        }

        result.curveName = curveName.length > 0 ? curveName.join(' ') : null;

        for (const part of remainingParts) {
            if (reservedStartWords.includes(part.toLowerCase())) {
                result.start = part;
            } else if (tenorPattern.test(part)) {
                result.tenors.push(part);
            } else if (dv01Pattern.test(part)) {
                result.dv01 = part;
            } else if (pricePattern.test(part)) {
                result.price = part;
            } else if (sizePattern.test(part)) {
                result.size = part;
            }
        }

        const {
            start, dv01, price, tenors, size
        } = result;

        if (result.curveName || start || dv01 || price || size || (tenors.length > 0 && tenors.length < 4)) {
            result.isValid = true;
        }

        if (tenors.length > 3) {
            result.isValid = false;
        }
        if (result.isValid === true && tenors.length > 0) {
            const formattedTenors = tenors.map((tenor) => {
                const value = tenor.replace(/(\d+)\s*([a-zA-Z])/, (match, number, unit) => `${number} ${unit.toUpperCase()}`);
                if (value === '12 M') return '1 Y';
                return value;
            });
            const unitOrder = ['D', 'W', 'M', 'Y'];

            const sortedTenors = formattedTenors.sort((a, b) => {
                const [numA, unitA] = a.split(' ');
                const [numB, unitB] = b.split(' ');

                const unitComparison = unitOrder.indexOf(unitA) - unitOrder.indexOf(unitB);
                if (unitComparison !== 0) return unitComparison;

                return parseInt(numA, 10) - parseInt(numB, 10);
            });
            result.tenors = sortedTenors;
        }

        return result;
    };

    useEffect(() => {
        if (!forDashboard) {
            searchBoxRef.current.focus();
        }
    }, []);

    const getResults = (value) => {
        socketEmit('/global-search', { phrase: value });
    };
    const getResults2 = (data) => {
        socketEmit('/search/voice', data);
    };
    const debouncedInputHandler = useRef(debounce(getResults, 800, { leading: false }));
    const debouncedInputHandler2 = useRef(debounce(getResults2, 300, { leading: false }));

    const handleInputChange = (e) => {
        setSearchPhrase(e.target.value);
        if (!e.target.value) { setSuggestions(() => []); setCommandsAndUsers(options); return; }
        if (!forDashboard && e.target.value.length) setShowSuggestions(() => true);
        if (e.target.value.length < 2) return;
        debouncedInputHandler.current(e.target.value);
    };

    const getFilteredCommands = (searchPhrase) => options[0].items
        .filter((optionItem) => {
            const isStartsWithPlot = ((optionItem.command === 'PLOT') && searchPhrase.toLowerCase().startsWith('plot '));
            const isStartsWithInp = ((optionItem.command === 'INP') && searchPhrase.toLowerCase().startsWith('inp '));
            const isStartsWithOtc = ((optionItem.command === 'OTC') && searchPhrase.toLowerCase().startsWith('otc '));
            const isStartsWithCorp = ((optionItem.command === 'CORP') && searchPhrase.toLowerCase().includes('corp'));

            return (isStartsWithPlot || isStartsWithInp || isStartsWithOtc || isStartsWithCorp) || optionItem.command.toLowerCase().includes(searchPhrase.trim().toLowerCase())
                || optionItem.label.toLowerCase().includes(searchPhrase.toLowerCase())
                || (optionItem.command.toLowerCase() === 'yas' && matchYasCommandWithPrice(searchPhrase.toLowerCase()));
        });

    const mixPanelEvent = (type, results, ref) => {
        const count = results?.reduce((acc, curr) => acc + curr?.items?.length, 0);
        const searchQuery = ref?.current?.value;
        trackEvent('Search', {
            'Search Query': searchQuery,
            Count: count,
            'User Id': auth.id,
            Type: type
        });
    };
    const byLexical = (a, b) => Number(a.lexicalRank) > Number(b.lexicalRank);
    const filterAndSortBonds = (phrase, items) => {
        const hasPhrase = []; const others = [];
        items.forEach((item) => (item.name.toLowerCase().startsWith(phrase) ? hasPhrase.push(item) : others.push(item)));
        return [...hasPhrase.sort(byLexical), ...others.sort(byLexical)];
    };

    const onResultsFetch = ({ results, phrase, error }) => {
        if (error) { setSuggestions([]); return; }
        if (!results.length) setSuggestions([]);
        const suggestions = results;
        const indexOfSecurities = suggestions.findIndex((suggestion) => suggestion.category === 'Securities');
        if (indexOfSecurities > -1) {
            const bonds = []; const others = [];
            suggestions[indexOfSecurities].items.forEach((item) => (item.type === 'Bond' ? bonds.push(item) : others.push(item)));
            const sortedBonds = filterAndSortBonds(phrase, bonds);
            const sortedItems = [...sortedBonds, ...others].sort((a, b) => {
                if (priorityMap[a.type] < priorityMap[b.type]) return -1;
                if (priorityMap[a.type] > priorityMap[b.type]) return 1;
                if (a.type !== 'Bond') {
                    if (Number(a.lexicalRank) < Number(b.lexicalRank)) return -1;
                    if (Number(a.lexicalRank) > Number(b.lexicalRank)) return 1;
                    return (Number(a.days) - Number(b.days));
                }
            });
            suggestions[indexOfSecurities].items = sortedItems;
        }
        if (clubUsersIntoFunctions) {
            const userCategory = suggestions.find((suggestion) => suggestion.category === 'Users');
            if (userCategory) {
                const formattedUsers = userCategory.items.map((user) => (
                    {
                        id: user.id, group: 'Users', command: `BIO ${user.name}`, label: ` ${user.companyName}`, type: 'Function'
                    }));
                setSearchPhrase((prevState) => {
                    const formattedCommands = getFilteredCommands(prevState);
                    if (formattedCommands.length || formattedUsers.length) {
                        setCommandsAndUsers([{ category: 'Functions', items: [...formattedCommands, ...formattedUsers] }]);
                    } else {
                        setCommandsAndUsers([]);
                    }
                    return prevState;
                });
            }
            if (searchBoxRef?.current?.value) {
                mixPanelEvent('Command Prompt', results, searchBoxRef);
            }
            setSuggestions(suggestions.filter((suggestion) => suggestion.category !== 'Users'));
            return;
        }
        if (searchBoxRef?.current?.value) {
            mixPanelEvent('Global Search', results, searchBoxRef);
        }

        setSuggestions(suggestions);
    };

    useEffect(() => {
        allSuggestions.every((item, index1) => {
            if (item.category === 'Functions') {
                item.items.every((item2, index2) => {
                    if ((item2.command.toLowerCase() === searchPhrase.toLowerCase())
                        || (item2.command.toLowerCase() === 'yas' && matchYasCommandWithPrice(searchPhrase.toLowerCase()))
                        || (item2.command.toLowerCase() === 'corp' && searchPhrase.toLowerCase().includes('corp'))
                    ) {
                        parentIndex.current = index1;
                        childIndex.current = index2;
                        setHighlightedId(`${allSuggestions[index1].category}-${index2}`);
                        return false;
                    }
                    if (highlightedId) {
                        setHighlightedId(() => '');
                        parentIndex.current = 0;
                        childIndex.current = -1;
                    }

                    return true;
                });
            }
            return true;
        });
    }, [commandsAndUsers]);

    useEffect(() => {
        if (searchPhrase === 'PLOT ' || searchPhrase === 'INP ' || searchPhrase === 'OTC' || (searchPhrase === 'CORP' && !isCorpChildComponent)) return;
        if (!searchPhrase.length) {
            setSuggestions(() => []);
            setShowSuggestions(() => false);
            return;
        }
        if (searchPhrase.length) {
            setShowSuggestions(() => true);

            if (forDetailsPage) {
                const filteredCommands = getFilteredCommands(searchPhrase);
                if (filteredCommands.length) {
                    setCommandsAndUsers(() => [{ category: 'Functions', items: filteredCommands }]);
                } else {
                    setCommandsAndUsers([]);
                }
            }
        }
    }, [searchPhrase]);

    const resetCursor = () => {
        childIndex.current = -1;
        parentIndex.current = 0;
        setHighlightedId('');
    };

    const handleClickOutside = (e) => {
        if (inputRef.current && !inputRef.current.contains(e.target)) {
            setShowSuggestions(false);
            !forDashboard && setSearchPhrase(() => '');
            resetCursor();
        }
    };
    const onPlotFetch = (data) => {
        const plotType = { irs: 'irs', currency: 'currencies' };
        if (data.error) return;
        if (data.command === 'fwcm') {
            if (data.type !== 'irs') return;
            openInSamePage(`${process.env.NEXT_PUBLIC_UI_URL}fwcm/${plotType[data.type]}/${data.id}`);
            return;
        }
        if (data.command === 'INP') {
            let inpUrl = '';
            if (data.type === 'irs') {
                if (!data?.irsSecurityId) return;
                inpUrl = `${process.env.NEXT_PUBLIC_UI_URL}${data.type}/${data.irsSecurityId}?inp=1`;
                sessionStorage.setItem(`${data.irsSecurityId}_input`, JSON.stringify(data));
                openInSamePage(inpUrl);
                return;
            }
            if (data.type === 'currency') {
                if (!data?.currencySecurityId) return;
                inpUrl = `${process.env.NEXT_PUBLIC_UI_URL}${plotType[data.type]}/${data.currencySecurityId}?inp=1`;
                sessionStorage.setItem(`${data.currencySecurityId}_input`, JSON.stringify(data));
                openInSamePage(inpUrl);
                return;
            }
        }
        const url = `${process.env.NEXT_PUBLIC_UI_URL}plot/${plotType[data.type]}/${data.id}?legs=${data.term}${data.spot ? '&spot=1' : ''}${data.type === 'irs' && data.isFwd ? '&command=fwcm' : ''}`;
        if (forDetailsPage) openInSamePage(url);
        else openTargetPage(url);
        setSearchPhrase('');
        resetCursor();
    };

    useEffect(() => {
        socketConnect();
        socket.on('/global-search', onResultsFetch);
        socket.on('/search/voice', onResultsFetch);
        socket.on('/plots/search', onPlotFetch);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            socket.off('/global-search', onResultsFetch);
            socket.off('/search/voice', onResultsFetch);
            socket.off('/plots/search', onPlotFetch);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const scrollElementToView = (id, start = false) => {
        const div = document.getElementById('search-dropdown');
        if (!div) return;
        const divRect = div.getBoundingClientRect();
        const elem = document.getElementById(id);
        if (elem) {
            const rect = elem.getBoundingClientRect();

            if (divRect.bottom < rect.bottom || divRect.top > rect.top) {
                elem.scrollIntoView({ behavior: 'smooth', block: start ? 'start' : 'nearest', inline: start ? 'nearest' : 'start' });
            }
        }
    };
    useEffect(() => {
        if (suggestions.length) {
            const timeoutId = setTimeout(() => {
                parentIndex.current = 0;
                childIndex.current = 0;
                const hash = `${allSuggestions[parentIndex.current]?.category}-${childIndex.current}`;
                setHighlightedId(hash);
                scrollElementToView(hash);
            }, 200);

            return () => clearTimeout(timeoutId);
        }
    }, [suggestions]);

    const openTargetPage = (url) => {
        window.open(url, nanoid(5), popupSpecsForDes);
    };
    const openInSamePage = (url) => {
        window.open(url, '_self');
    };
    const onClick = (item) => {
        if (!item) return;
        const baseUrl = process.env.NEXT_PUBLIC_UI_URL;
        const urlMap = {
            Bond: (bond) => `${baseUrl}bonds/${bond.id}?command=${selectedCommand}`,
            MaturedBond: (bond) => `${baseUrl}bonds/${bond.id}?command=${selectedCommand}`,
            Irs: (irsSecurity) => `${baseUrl}irs/${irsSecurity.id}?command=${selectedCommand}`,
            Index: (bond) => `${baseUrl}indexes/${bond.id}?command=${selectedCommand}`,
            Cds: (cds) => `${baseUrl}cds/${cds.id}?command=${selectedCommand}`,
            Equity: (equity) => `${baseUrl}equities/${equity.ticker}?command=${selectedCommand}`,
            User: (user) => `${baseUrl}users/${user.id}`,
            Trsy: `${baseUrl}trsy`,
            Ndf: (ndf) => `${baseUrl}currencies/${ndf.id}?command=${selectedCommand}`,
            Currency: (currency) => `${baseUrl}equities/${currency.ticker}?stockExchange=forex&command=${selectedCommand}`,
            QuickCurrency: (currency) => `${baseUrl}equities/${currency.ticker}?stockExchange=forex&command=${selectedCommand}`
        };
        if (item.command === 'FWCM') { fwcm(); }
        if (item.type === 'Function') {
            if (item.group === 'Users') {
                openInSamePage(urlMap.User(item));
            } else if (item.command === 'SHARE') {
                takeScreenShot();
            } else if (item.command === 'PLOT' || item.command === 'INP' || item.command === 'FWCM' || item.command === 'OTC' || (item.command === 'CORP' && !isCorpChildComponent)) {
                setSearchPhrase(`${item.command} `);
            } else {
                const commandSelected = item.command;
                if (commandSelected === 'CORP') {
                    if (searchPhrase.toLowerCase().includes('corp')) {
                        const words = searchPhrase.trim().toLowerCase().split(/\s+/);
                        const ticker = words.find((word) => word !== 'corp');

                        if (isCorpChildComponent) {
                            setTicker(ticker);
                        } else {
                            const url = `${process.env.NEXT_PUBLIC_UI_URL}corp/${ticker}`;
                            openInSamePage(url);
                            return;
                        }
                    }
                }
                onSelect(commandSelected === 'RFQ' ? `${commandSelected}-${Date.now()}` : commandSelected);
            }
        } else if (forDetailsPage) {
            openInSamePage(urlMap[item.type](item));
        } else openTargetPage(urlMap[item.type](item));
        setShowSuggestions(false);
        setSuggestions([]);
        setCommandsAndUsers(options);
        if (item.command !== 'PLOT' && item.command !== 'INP' && item.command !== 'FWCM' && item.command !== 'OTC' && ((isCorpChildComponent) || (!isCorpChildComponent && item.command !== 'CORP'))) setSearchPhrase('');
        resetCursor();
        !forDashboard && searchBoxRef.current.focus();
    };
    const scrollElement = (id, start = false) => {
        const div = document.getElementById('search-dropdown');
        let scroll = false;
        if (!div) return scroll;
        const divRect = div.getBoundingClientRect();
        const elem = document.getElementById(id);
        if (elem) {
            const rect = elem.getBoundingClientRect();

            if (divRect.bottom < rect.bottom || divRect.top > rect.top) {
                elem.scrollIntoView({ behavior: 'smooth', block: start ? 'start' : 'end', inline: 'nearest' });
                scroll = true;
            }
        }
        return scroll;
    };
    const handleSelect = (number) => {
        if (number <= 0) {
            const hash = `${allSuggestions[parentIndex.current]?.category}-${childIndex.current}`;
            setHighlightedId(hash);
            scrollElement(hash, false);
            return;
        } if (childIndex.current < allSuggestions[parentIndex.current]?.items.length - 1) {
            childIndex.current += 1;
        } else if (parentIndex.current < allSuggestions?.length - 1) {
            parentIndex.current += 1;
            childIndex.current = 0;
        } else {
            parentIndex.current = 0;
            childIndex.current = 0;
        }
        handleSelect(number - 1);
    };
    const handleDown = (next) => {
        if (!next) return;
        if (childIndex.current < allSuggestions[parentIndex.current].items.length - 1) {
            childIndex.current += 1;
        } else if (parentIndex.current < allSuggestions.length - 1) {
            parentIndex.current += 1;
            childIndex.current = 0;
        } else {
            parentIndex.current = 0;
            childIndex.current = 0;
            next = false;
        }
        const hash = `${allSuggestions[parentIndex.current]?.category}-${childIndex.current}`;
        setHighlightedId(hash);
        const scroll = scrollElement(hash, true);
        if (!scroll) handleDown(next);
    };
    const handleUp = (next) => {
        if (!next) return;
        if (childIndex.current > 0) {
            childIndex.current -= 1;
        } else if (parentIndex.current > 0) {
            parentIndex.current -= 1;
            childIndex.current = allSuggestions[parentIndex.current].items.length - 1;
        } else {
            parentIndex.current = allSuggestions.length - 1;
            childIndex.current = allSuggestions[parentIndex.current].items.length - 1;
            next = false;
        }
        const hash = `${allSuggestions[parentIndex.current]?.category}-${childIndex.current}`;
        setHighlightedId(hash);
        const scroll = scrollElement(hash, false);
        if (!scroll) handleUp(next);
    };

    const handleF3KeyPress = () => {
        const searchInput = document.getElementById('global-search');
        if (searchInput) {
            searchInput.focus();

            if (!searchInput.value.toLowerCase().includes('corp')) {
                const value = `corp ${searchInput.value.trim()}`;
                searchInput.value = value;
                setSearchPhrase(value);
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.code === 'F3') {
            event.preventDefault();
            handleF3KeyPress();
            return;
        }
        if (allSuggestions.length && event.code === 'ArrowDown') {
            if (childIndex.current < allSuggestions[parentIndex.current]?.items.length - 1) {
                childIndex.current += 1;
            } else if (parentIndex.current < allSuggestions?.length - 1) {
                parentIndex.current += 1;
                childIndex.current = 0;
            } else {
                parentIndex.current = 0;
                childIndex.current = 0;
            }
            const hash = `${allSuggestions[parentIndex.current]?.category}-${childIndex.current}`;
            setHighlightedId(hash);
            scrollElementToView(hash);
        }
        if (allSuggestions.length && event.code === 'ArrowUp') {
            if (childIndex.current > 0) {
                childIndex.current -= 1;
            } else if (parentIndex.current > 0) {
                parentIndex.current -= 1;
                childIndex.current = allSuggestions[parentIndex.current].items.length - 1;
            } else {
                parentIndex.current = allSuggestions.length - 1;
                childIndex.current = allSuggestions[parentIndex.current].items.length - 1;
            }
            const hash = `${allSuggestions[parentIndex.current].category}-${childIndex.current}`;
            setHighlightedId(hash);
            scrollElementToView(hash);
        }
        if (event.code === 'Enter') {
            if (matchYasCommandWithPrice(searchPhrase.toLowerCase())) {
                const item = options.find((suggestion) => suggestion.category === 'Functions').items.find((item) => item.command === 'YAS');
                if (item) {
                    setYasPrice(getPriceFromYasWithPrice(searchPhrase.toLowerCase()));
                    onClick(item);
                    return;
                }
            }
            if (searchPhrase.toLowerCase().includes('otc')) {
                const currency = searchPhrase.split(' ')[1]?.toLowerCase();
                const url = `${process.env.NEXT_PUBLIC_UI_URL}otc/${currency}`;
                openInSamePage(url);
                return;
            }

            if (searchPhrase.toLowerCase().includes('corp')) {
                const words = searchPhrase.trim().toLowerCase().split(/\s+/);
                const ticker = words.find((word) => word !== 'corp');
                if (isCorpChildComponent) {
                    setTicker(ticker);
                } else {
                    const url = `${process.env.NEXT_PUBLIC_UI_URL}corp/${ticker}`;
                    openInSamePage(url);
                    return;
                }
            }

            if (!searchPhrase.toLowerCase().includes('inp')
                && !searchPhrase.toLowerCase().startsWith('inp ')
                && parsePlot(searchPhrase).isValid
            ) {
                const {
                    name, tenors, spot, isFwd
                } = parsePlot(searchPhrase);
                if (!name && forDetailsPage) {
                    plot(tenors, spot, isFwd);
                    return;
                }
                socketEmit('/plots/search', {
                    phrase: name, tenors, spot, isFwd
                });
                return;
            }
            if (searchPhrase.trim().toLowerCase().startsWith('fwcm') || searchPhrase.trim().toLowerCase().endsWith('fwcm')) {
                if (searchPhrase.trim().toLowerCase() === 'fwcm') fwcm();
                else {
                    const name = searchPhrase.trim().toLowerCase().replace('fwcm', '').trim();
                    socketEmit('/plots/search', { phrase: name, command: 'fwcm', tenors: [] });
                }
                return;
            }

            const { isValid: isValidInput } = parseInput(searchPhrase?.trim());

            if (childIndex.current === -1 && !isValidInput) return;
            const item = allSuggestions[parentIndex.current]?.items[childIndex.current];
            if (!item) {
                if (!searchPhrase.toLowerCase().includes('plot') && isValidInput) {
                    const res = parseInput(searchPhrase?.trim());
                    const {
                        curveName, tenors, start, dv01, price, size
                    } = res;
                    let updatedTenors = tenors;
                    if (!curveName && !tenors?.length) {
                        const pathArray = router.asPath.split('?')[0].split('/');
                        if (pathArray[1] === 'irs' || pathArray[1] === 'currencies') {
                            sessionStorage.setItem(`${pathArray[2]}_input`, JSON.stringify({
                                curveName, term: tenors, start, dv01, price, size
                            }));
                            const url = new URL(window.location.href);
                            url.searchParams.set('inp', '1');
                            window.location.href = url.toString();
                            return;
                        }
                        if (isPlotPage) {
                            updatedTenors = legs;
                        } else {
                            return;
                        }
                    }
                    const phrase = curveName || curve;
                    socketEmit('/plots/search', {
                        phrase, tenors: updatedTenors, start, dv01, price, size, command: 'INP'
                    });
                    return;
                }
                return;
            }
            if (searchPhrase && (!['INP', 'PLOT', 'CORP'].includes(searchPhrase.trim()) || isCorpChildComponent)) {
                onClick(item);
            }
            setYasPrice(null);
            setShowSuggestions(false);
            resetCursor();
            forDashboard ? document.getElementById('global-search')?.blur() : searchBoxRef.current.focus();
        }
    };
    useEffect(() => {
        if (!globalCommand?.value) return;
        const commandText = globalCommand.value.toString().toLowerCase().trim();
        const functions = options.find((item) => item.category === 'Functions')?.items;
        const item = functions?.find((item) => item.command.toLowerCase() === commandText && item.group === 'Functions');

        if (globalCommand.type === 'command' && item) {
            setSearchPhrase('');
            if (item.command === 'SHARE') {
                takeScreenShot();
            } else {
                onSelect(item.command);
            }
        } else if (globalCommand.type === 'search') {
            searchBoxRef.current.focus();
            setSearchPhrase(commandText);
            debouncedInputHandler2.current({ phrase: commandText });
        } else if (globalCommand.type === 'scroll') {
            globalCommand.value === 'ArrowUp' ? handleUp(true) : handleDown(true);
        } else if (globalCommand.type === 'select') {
            parentIndex.current = 0;
            childIndex.current = -1;
            handleSelect(globalCommand.value);
        } else if (globalCommand.type === 'enter') {
            handleKeyDown({ code: 'Enter' });
        }
    }, [globalCommand]);

    useEffect(() => {
        if (isF3Entered) {
            handleF3KeyPress();
            setIsF3Entered(false);
        }
    }, [isF3Entered]);

    const getDisplayName = (item) => {
        if (item.type === 'Equity') return `${item.name} (${item.ticker})`;
        if (item.type === 'Currency') return `${item.ticker}`;
        if (item.type === 'QuickCurrency') return `${item.ticker}`;
        if (item.type === 'Function') return `${item.command} -`;
        return item.name;
    };

    const getDisplayType = (item) => {
        if (item.type === 'Ndf') return 'FX';
        if (item.type === 'QuickCurrency') return 'Currency';
        if (item.type === 'MaturedBond') return 'Bond';
        return item.type;
    };

    const getAdditionalInfo = (item) => {
        if (item.type === 'User') return `- ${item.companyName}`;
        if (item.type === 'Function') return `${item.label}`;
    };

    return <div className='relative' ref={inputRef}>
        {(enableVoiceCommand && (forDetailsPage || voiceCommand)) && <Dictaphone />}
        <SearchBox
            type='text'
            id='global-search'
            value={searchPhrase}
            placeholder={placeholder}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            autoComplete='off'
            inputClass={`${inputClass} cursor-text`}
            icon={icon}
            iconClass={iconClass}
            onFocus={() => forDashboard && setShowSuggestions(true)}
            reference={searchBoxRef}
        />
        {showSuggestions && (
            <div id='search-dropdown' className={`absolute z-[99] bg-gray-600 w-full rounded-md mt-0.5 overflow-y-auto max-h-96 ${globalSearchList} ${suggestionBg}`}
                onMouseEnter={resetCursor}>
                {allSuggestions.map((suggestion, index) => (
                    <div key={index} className="categoryBox">
                        <div className="px-3.5 py-1.5">
                            <Text variant="caption" className={`text-primary-100 ${categoryTitleClass}`}>
                                {suggestion.category}
                            </Text>
                        </div>
                        <div className="catList">
                            {suggestion.items.map((item, itemIndex) => (
                                <div id={`${suggestion.category}-${itemIndex}`}
                                    key={itemIndex}
                                    className={`flex justify-between group py-1.5 px-4 hover:bg-gray-500 cursor-pointer 
                                        ${`${suggestion.category}-${itemIndex}` === highlightedId && `bg-gray-500 ${highlightBg}`} ${categoryList}`}
                                    onClick={() => onClick(item)}
                                >
                                    <Text
                                        variant="caption"
                                        className={`text-gray-100 font-medium flex items-center gap-1 group-hover:text-gray-100 break-keep ${suggestionName}`}
                                    >
                                        {getDisplayName(item)}
                                        {(item.type === 'User' || item.type === 'Function') && !item.formattingStyle
                                            && <span className='text-warning-200 text-xs font-medium capitalize'>{getAdditionalInfo(item)}</span>
                                        }
                                        {item.formattingStyle === 'html'
                                            && <span className='text-warning-200 text-xs font-medium capitalize'>{item.html}</span>
                                        }

                                    </Text>
                                    {item.type !== 'Function'
                                        && <Text variant="caption" className={`text-gray-100 uppercase group-hover:text-gray-100 whitespace-pre ml-2 ${suggestionName}`}>
                                            {getDisplayType(item)}
                                        </Text>}
                                </div>
                            ))}

                        </div>
                    </div>
                ))}
            </div>
        )
        }
    </div >;
};

export default GlobalSearch;
