const commonDropdownStyle = {
    control: (provided) => ({
        ...provided,
        borderWidth: '0px',
        borderColor: 'none',
        cursor: 'pointer',
        boxShadow: 'none',
        minHeight: '28px',
        '&:active': {
            borderColor: '#2962FF'
        },
        '.select2-selection__single-value': {
            fontSize: '12px'
        },
        '.select2-selection__value-container': {
            padding: '0'
        },
        '.select2-selection__input': {
            '.select2-selection__input::placeholder': {
                color: '#000 !important'
            }
        },
        '.select2-selection__multi-value': {
            background: '#1E222D',
            color: '#fff',
            padding: '4px 8px;',
            marginRight: '4px 6px',
            '.select2-selection__multi-value__label': {
                color: '#fff',
                padding: '0',
                marginRight: '8px',
                fontSize: '12px',
                lineHeight: '16px'
            },
            '.select2-selection__multi-value__remove': {
                padding: 0,
                '&:hover': {
                    background: 'none',
                    color: '#fff'
                }
            }
        }
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#1E222D'
    }),
    option: (provided) => ({
        ...provided,
        color: '#868993',
        fontSize: '12px',
        backgroundColor: '#1E222D',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#2A2E39',
            color: '#D1D4DC'
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        padding: '0 12px'
    }),
    input: (provided) => ({
        ...provided,
        margin: '0px',
        fontSize: '12px'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '100%'
    }),
    clearIndicator: (provided) => ({
        ...provided,
        position: 'absolute',
        top: '-7px',
        backgroundColor: 'red',
        padding: '2px',
        right: '-4px',
        borderRadius: '100px',
        fontSize: '10px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '15px',
        height: '15px',
        '&:hover': {
            color: '#000',
            padding: '1px'
        }
    })
};

export default commonDropdownStyle;
