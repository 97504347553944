import Button from 'atoms/Button';
import Heading from 'atoms/Heading';
import Text from 'atoms/Text';
import CircularCountDown from 'molecules/CircularCountDown';
import { useEffect, useState } from 'react';
import {
    ChevronLeft, ChevronRight, Maximize2, Minimize2
} from 'react-feather';
import { socketEmit } from 'services/socket';
import ApprovalGrid from './ApprovalGrid';

const ApprovalWaiting = ({ infoTable, setAwaitingTradeRequests, closePopup }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const TOTAL_SECONDS = 120;

    const handleRequestExpiry = (expiredItemIndex) => {
        const updatedRequests = infoTable?.filter((_, index) => index !== expiredItemIndex);

        setAwaitingTradeRequests(updatedRequests);

        if (updatedRequests?.length === 0) {
            closePopup();
        } else {
            setCurrentIndex((prevIndex) => Math.min(prevIndex, updatedRequests?.length - 1));
        }
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % infoTable?.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + infoTable?.length) % infoTable?.length);
    };

    const calculateSeconds = (RequestExpiry) => {
        const expiryTime = new Date(RequestExpiry);
        const currentTime = new Date();
        return Math.max(Math.floor((expiryTime - currentTime) / 1000), 0);
    };

    const cancelTradeRequest = (currentIndex) => {
        handleRequestExpiry(currentIndex);

        socketEmit('/post/trade-requests/:id/action', {
            id: infoTable?.[currentIndex].tradeRequestId,
            action: 'Cancel',
            bondType: infoTable?.[currentIndex].bondType,
            bondPriceId: infoTable?.[currentIndex].bondPriceId,
            requestorId: infoTable?.[currentIndex].requestorId,
            tradeSize: infoTable?.[currentIndex].tradeSize
        });
    };

    const secondsRemaining = infoTable?.length > 0 ? calculateSeconds(infoTable[currentIndex]?.expiry) : 0;

    useEffect(() => {
        setCurrentIndex(0);
    }, [infoTable]);

    if (infoTable?.length === 0) return null;

    return (
        <div className={`w-[362px] rounded-md outline outline-gray-400 outline-1 fixed bottom-5 left-5 z-50
         duration-700 ease-in-out ${!isExpanded && '-bottom-[5px]'} `}>
            <div className='flex items-center h-12 bg-gray-700 px-3'>
                <Text variant="bodySmall" className="text-gray-100 basis-[60%]" fontWeight="font-medium">
                    {isExpanded ? 'Waiting For Approval' : infoTable?.[currentIndex].bondName}
                </Text>
                <div className='justify-between items-center flex flex-1 gap-x-2'>
                    <CircularCountDown
                        seconds={secondsRemaining}
                        totalSeconds={TOTAL_SECONDS}
                        onExpiry={() => handleRequestExpiry(currentIndex)}
                    />
                    <div className='flex items-center'>
                        <ChevronLeft
                            size={24}
                            className='text-gray-100 p-1 cursor-pointer'
                            onClick={handlePrev}
                        />
                        <Text variant="bodySmall" className="text-basic-100 font-lato tracking-widest">
                            {`${currentIndex + 1}/${infoTable?.length}`}
                        </Text>
                        <ChevronRight
                            size={24}
                            className='text-gray-100 p-1 cursor-pointer'
                            onClick={handleNext}
                        />
                    </div>
                    {isExpanded
                        ? <Minimize2
                            size={34}
                            className='text-gray-100 p-2 cursor-pointer'
                            onClick={() => setIsExpanded(false)}
                        />
                        : <Maximize2
                            size={34}
                            className='text-gray-100 p-2 cursor-pointer'
                            onClick={() => setIsExpanded(true)}
                        />
                    }
                </div>
            </div>
            {isExpanded && (
                <div className='bg-gray-600 px-6 py-4'>
                    <Heading type='h6' className='font-medium text-center text-gray-100'>{infoTable?.[currentIndex]?.bondName}</Heading>
                    <ApprovalGrid data={infoTable?.[currentIndex]?.details} />
                    <Button
                        type='ghost'
                        label="Cancel Request"
                        className='!text-error-200 !border-error-200 mt-8 focus:!bg-transparent'
                        fullWidth
                        onClick={() => cancelTradeRequest(currentIndex)}
                    />
                </div>
            )}
        </div>
    );
};

export default ApprovalWaiting;
