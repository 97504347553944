import { assestTypeMapping } from 'helper/irs.helper.service';
import { trackEvent } from 'helper/mixPanel';
import { useEffect, useRef, useState } from 'react';
import { getAuth } from 'services/auth.service';
import { socketEmit } from 'services/socket';
import Button from '../atoms/Button';
import Heading from '../atoms/Heading';
import Text from '../atoms/Text';
import DialogBox from '../molecules/DialogBox';
import ApprovalGrid from './ApprovalGrid';

const ApprovalPopup = ({
    infoTable = [], closePopup, tradeRequestAction, resetTimer = () => { }, isInsufficientSize
}) => {
    const [timer, setTimer] = useState(resetTimer());
    const timerStartRef = useRef(Date.now());
    const initialTimerRef = useRef(resetTimer());

    const acceptTradeRequest = () => {
        const mixPanelObject = {
            'User Id': infoTable.requestorId,
            'Asset Type': assestTypeMapping[infoTable.bondType?.toUpperCase()],
            'Action Type': infoTable.requestType === 'Bid' ? 'Hit The Bid' : 'Lift The Offer',
            Name: infoTable.bondName
        };

        socketEmit('/post/trade-requests/:id/action', {
            id: infoTable.tradeRequestId,
            action: 'Accept',
            bondType: infoTable.bondType,
            bondPriceId: infoTable.bondPriceId,
            requestorId: infoTable.requestorId,
            tradeSize: infoTable.tradeSize
        });
        trackEvent('Hit the Bid / Lift the Offer', mixPanelObject);
    };

    const declineTradeRequest = () => {
        socketEmit('/post/trade-requests/:id/action', {
            id: infoTable.tradeRequestId,
            action: 'Decline',
            bondType: infoTable.bondType,
            bondPriceId: infoTable.bondPriceId,
            requestorId: infoTable.requestorId,
            tradeSize: infoTable.tradeSize
        });
    };

    const showIgnoreOption = () => (infoTable.isTraderPresent && getAuth().role === 'Broker');

    useEffect(() => {
        const updateTimer = () => {
            const now = Date.now();
            const elapsedSeconds = Math.floor((now - timerStartRef.current) / 1000);
            const remainingSeconds = Math.max(initialTimerRef.current - elapsedSeconds, 0);

            setTimer(remainingSeconds);

            if (remainingSeconds === 0) {
                closePopup();
            }
        };

        const interval = setInterval(updateTimer, 1000);

        return () => clearInterval(interval);
    }, [closePopup]);

    useEffect(() => {
        timerStartRef.current = Date.now();
        initialTimerRef.current = resetTimer();
        setTimer(initialTimerRef.current);
    }, [resetTimer]);

    return (
        <div>
            <DialogBox
                title="Approval Required"
                subTitle={infoTable.bondName}
                width='w-full max-w-[448px]'
                isClose={false}
                titleStyle='text-center text-2xl mb-2 !mt-0'
                subTitleStyle='!text-lg'
                bodySpacing="mb-0"
            >
                <Text className="text-gray-100 text-center">A trader is trying to {infoTable.requestType === 'Bid' ? 'hit your bid' : 'lift your offer'} in '<span className='font-semibold'>{infoTable.size}</span>'.</Text>
                <ApprovalGrid data={infoTable.details} />
                <Text variant="caption" className="text-success-600">Expires in... {timer} sec</Text>
                <div className='flex flex-col gap-3 mt-8'>
                    <Button label="Accept" type='primary' onClick={() => acceptTradeRequest()} fullWidth />
                    <Button label='Decline' type='ghost' buttonTheme='gray' onClick={() => declineTradeRequest()} className="!text-error-200 !border-error-200" fullWidth />
                    {showIgnoreOption() && <Button label="Ignore" type='borderLess' className="!text-gray-100" fullWidth onClick={() => closePopup()} />}
                </div>
            </DialogBox>
            {tradeRequestAction === 'Accept'
                && <DialogBox
                    title={`Trade Request - ${infoTable.bondName}`}
                    width='w-full max-w-[448px]'
                    isClose={false}
                    titleStyle='text-center text-xl mb-2 !mt-0'
                    bodySpacing="mb-0"
                >
                    <Heading type='h6' className='font-semibold text-gray-100 h-8 rounded bg-success-300 text-center'>Accepted</Heading>
                    <Text variant="bodySmall" className="text-gray-100 text-center mt-2">Your trade request has been executed successfully.</Text>
                    <ApprovalGrid data={infoTable.details} tradeRequestAction={tradeRequestAction} />
                    <Button label="Ok" type='primary' className="mt-8" fullWidth onClick={() => closePopup()} />
                </DialogBox>
            }
            {['Decline', 'CancelPrice', 'InsufficientSize'].includes(tradeRequestAction)
                && <DialogBox
                    title={`Trade Request - ${infoTable.bondName}`}
                    width='w-full max-w-[448px]'
                    isClose={false}
                    titleStyle='text-center text-xl mb-2 !mt-0'
                    bodySpacing="mb-0"
                >
                    <Heading type='h6' className='font-semibold text-gray-100 h-8 rounded bg-error-300 text-center'>Declined</Heading>
                    <Text variant="bodySmall" className="text-gray-100 text-center mt-2">Your trade request has been declined, {isInsufficientSize ? 'Requested size is not available' : 'the price is no longer valid.'}</Text>
                    <ApprovalGrid data={infoTable?.details} tradeRequestAction={tradeRequestAction} />
                    <Button label="Ok" type='primary' className="mt-8" fullWidth onClick={() => closePopup()} />
                </DialogBox>
            }

        </div>
    );
};

export default ApprovalPopup;
